<template>
  <div>
    <!-- Mobile Header -->
    <header class="default heade-sticky border-b-gradient d-lg-none d-flex">
      <router-link to="/">
        <div class="un-item-logo">
          <img
            class="logo-img light-mode"
            width="159"
            height="34"
            src="/images/logo_b.svg"
            alt=""
          />
          <img
            class="logo-img dark-mode"
            width="159"
            height="34"
            src="/images/circle-logo.svg"
            loading="lazy"
            alt=""
          />
        </div>
      </router-link>
      <div class="un-block-right">
        <!-- menu-sidebar -->
        <div class="menu-sidebar h2">
          <button
            type="button"
            name="sidebarMenu"
            aria-label="sidebarMenu"
            class="btn"
            data-bs-toggle="modal"
            data-bs-target="#mdllSidebar-connected"
          >
            <svg
              stroke="currentColor"
              fill="currentColor"
              stroke-width="0"
              viewBox="0 0 24 24"
              height="1em"
              width="1em"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g>
                <path fill="none" d="M0 0h24v24H0z"></path>
                <path d="M3 4h18v2H3V4zm6 7h12v2H9v-2zm-6 7h18v2H3v-2z"></path>
              </g>
            </svg>
          </button>
        </div>
      </div>
    </header>
    <!-- End Mobile Header -->

    <!-- Desktop Header -->
    <div class="d-lg-block d-none">
      <header class="py-3 container">
        <div class="row">
          <router-link
            to="/"
            class="
              col-2
              un-item-logo
              d-flex
              align-items-center
              mb-2 mb-md-0
              text-dark text-decoration-none
            "
            aria-label="logo"
          >
            <img
              class="logo-img light-mode"
              width="159"
              height="34"
              src="/images/logo_b.svg"
              alt=""
            />
            <img
              class="logo-img dark-mode"
              width="159"
              height="34"
              src="/images/logo-white.svg"
              loading="lazy"
              alt=""
            />
          </router-link>

          <ul class="col-7 nav mb-2 justify-content-center mb-md-0 gap-3">
            <li>
              <router-link to="/" class="nav-link px-2 link-dark"
                >Home</router-link
              >
            </li>
            <li>
              <router-link to="/search" class="nav-link px-2 link-dark"
                >Dapps</router-link
              >
            </li>
            <li>
              <router-link to="/token" class="nav-link px-2 link-dark"
                >Token</router-link
              >
            </li>
            <li>
              <router-link to="/nft" class="nav-link px-2 link-dark"
                >NFT</router-link
              >
            </li>
            <li>
              <router-link to="/category" class="nav-link px-2 link-dark"
                >Category</router-link
              >
            </li>
            <li>
              <router-link to="/blog" class="nav-link px-2 link-dark"
                >Blog</router-link
              >
            </li>
            <li>
              <router-link to="/advertise" class="nav-link px-2 text-shine"
                >Advertise</router-link
              >
            </li>
          </ul>

          <div
            class="col-3 text-end d-flex justify-content-end align-items-center"
          >
            <!-- <label class="switch_toggle toggle_lg theme-switch me-3" for="switchDark">
                        <input type="checkbox" class="switchDarkMode theme-switch" id="switchDark"
                            aria-describedby="switchDark">
                        <span class="handle"></span>
                    </label> -->
            <router-link
              to="/add-dapps"
              class="btn rounded-pill px-3 bg-success text-black"
            >
              Submit dApps
            </router-link>
          </div>
        </div>
      </header>
    </div>
    <!-- End Desktop Header -->
    <div class="space-sticky d-lg-none d-flex"></div>

    <!-- ===================================
      START THE MODAL SIDEBAR MENU - CONNECTED
    ==================================== -->
    <div
      class="modal sidebarMenu -left fade"
      id="mdllSidebar-connected"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-scrollable">
        <div class="modal-content">
          <div class="modal-header d-block pb-1">
            <!-- un-user-profile -->
            <div class="un-user-profile">
              <div class="text-user">
                <h3>Dino Dapps Store</h3>
                <p>Find All Dapps In here</p>
              </div>
            </div>

            <button
              type="button"
              class="btn btnClose"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i class="ri-close-fill"></i>
            </button>

            <!-- cover-balance -->
            <div class="d-flex justify-content-start w-100 custom-scroll gap-2">
              <div class="cover-balance col-11">
                <div class="un-balance">
                  <div class="content-balance">
                    <div class="head-balance">
                      <h4>Total dApps</h4>
                      <button
                        data-bs-dismiss="modal"
                        @click="$router.push('/how-to-submit')"
                        to="/how-to-submit"
                        class="btn link-addBalance"
                      >
                        <i class="ri-add-fill"></i>
                      </button>
                    </div>
                    <p class="no-balance">{{ productData }}</p>
                  </div>
                </div>
                <button
                  data-bs-dismiss="modal"
                  @click="$router.push('/add-dapps')"
                  class="btn btn-sm-size bg-white text-dark rounded-pill"
                >
                  Add
                </button>
              </div>

              <div class="cover-balance col-11">
                <div class="un-balance">
                  <div class="content-balance">
                    <div class="head-balance">
                      <h4>Network Support</h4>
                      <button
                        data-bs-dismiss="modal"
                        @click="$router.push('/add-dapps')"
                        class="btn link-addBalance"
                      >
                        <i class="ri-add-fill"></i>
                      </button>
                    </div>
                    <p class="no-balance">{{ networkData }} Network</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-body">
            <ul class="nav flex-column -active-links">
              <li class="nav-item">
                <button
                  data-bs-dismiss="modal"
                  class="nav-link btn w-100 d-flex align-items-center border-0"
                  @click="$router.push('')"
                >
                  <div class="icon_current">
                    <i class="ri-compass-line"></i>
                  </div>
                  <div class="icon_active">
                    <i class="ri-compass-fill"></i>
                  </div>
                  <span class="title_link">Discover</span>
                </button>
              </li>
              <li class="nav-item">
                <button
                  data-bs-dismiss="modal"
                  class="nav-link btn w-100 d-flex align-items-center border-0"
                  @click="$router.push('/search')"
                >
                  <div class="icon_current">
                    <i class="ri-search-line"></i>
                  </div>
                  <div class="icon_active">
                    <i class="ri-search-fill"></i>
                  </div>
                  <span class="title_link">Search</span>
                </button>
              </li>
              <li class="nav-item">
                <button
                  data-bs-dismiss="modal"
                  class="nav-link btn w-100 d-flex align-items-center border-0"
                  @click="$router.push('/category')"
                >
                  <div class="icon_current">
                    <i class="ri-menu-4-line"></i>
                  </div>
                  <div class="icon_active">
                    <i class="ri-apps-2-fill"></i>
                  </div>
                  <span class="title_link">Category</span>
                </button>
              </li>
              <li class="nav-item">
                <button
                  data-bs-dismiss="modal"
                  class="nav-link btn w-100 d-flex align-items-center border-0"
                  @click="$router.push('/token')"
                >
                  <div class="icon_current">
                    <i class="ri-coin-line"></i>
                  </div>
                  <div class="icon_active">
                    <i class="ri-apps-2-fill"></i>
                  </div>
                  <span class="title_link">Token Tracker</span>
                </button>
              </li>
              <li class="nav-item">
                <button
                  data-bs-dismiss="modal"
                  class="nav-link btn w-100 d-flex align-items-center border-0"
                  @click="$router.push('/nft')"
                >
                  <div class="icon_current">
                    <i class="ri-image-2-line"></i>
                  </div>
                  <div class="icon_active">
                    <i class="ri-apps-2-fill"></i>
                  </div>
                  <span class="title_link">NFT Tracker</span>
                </button>
              </li>
              <li class="nav-item">
                <button
                  data-bs-dismiss="modal"
                  class="nav-link btn w-100 d-flex align-items-center border-0"
                  @click="$router.push('/blog')"
                >
                  <div class="icon_current">
                    <i class="ri-article-line"></i>
                  </div>
                  <div class="icon_active">
                    <i class="ri-apps-2-fill"></i>
                  </div>
                  <span class="title_link">Blog</span>
                </button>
              </li>

              <label class="title__label">other</label>

              <li class="nav-item">
                <a class="nav-link" href="page-help.html">
                  <div class="icon_current">
                    <i class="ri-questionnaire-line"></i>
                  </div>
                  <div class="icon_active">
                    <i class="ri-questionnaire-fill"></i>
                  </div>
                  <span class="title_link">Help Center</span>
                </a>
              </li>

              <li class="nav-item">
                <router-link class="nav-link" to="/about">
                  <div class="icon_current">
                    <i class="ri-file-info-line"></i>
                  </div>
                  <div class="icon_active">
                    <i class="ri-file-info-fill"></i>
                  </div>
                  <span class="title_link">About Dino Dapps.</span>
                </router-link>
              </li>
            </ul>
          </div>
          <!-- <div class="modal-footer">
                    <div class="em_darkMode_menu">
                        <label class="text" for="switchDark">
                            <h3>Dark Mode</h3>
                            <p>Browsing in night mode</p>
                        </label>
                        <label
                            class="switch_toggle toggle_lg theme-switch"
                            for="switchDark"
                        >
                            <input
                            type="checkbox"
                            class="switchDarkMode theme-switch"
                            id="switchDark"
                            aria-describedby="switchDark"
                            aria-label="switchDark"
                            />
                            <span class="handle"></span>
                        </label>
                    </div>
                </div> -->
        </div>
      </div>
    </div>
    <!-- ===================================
      START THE CREATE STORY MODAL
    ==================================== -->
    <div
      class="modal sidebarMenu -left --fullScreen modal-filter fade"
      id="mdllAddStory"
      tabindex="-1"
      aria-labelledby="sidebarMenuLabel3"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-scrollable">
        <div class="modal-content">
          <div class="modal-header">
            <h1 class="title-modal">Create Story</h1>
            <button
              type="button"
              class="btn btnClose"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i class="ri-close-fill"></i>
            </button>
          </div>
          <div class="modal-body">
            <div class="un-create-collectibles bg-white p-0">
              <div class="form-group upload-form">
                <h2>Upload file</h2>
                <p>Choose your file to upload</p>
                <div class="upload-input-form">
                  <input type="file" />
                  <div class="content-input">
                    <div class="icon"><i class="ri-upload-cloud-line"></i></div>
                    <span>PNG, GIF, JPG, MP4 . Max 5 mb.</span>
                  </div>
                </div>
              </div>
              <div class="form-group icon-right">
                <label>Add Link</label>
                <div class="input_group">
                  <input
                    type="url"
                    class="form-control"
                    placeholder='e. g. "example.com"'
                  />
                  <div class="icon">
                    <i class="ri-link"></i>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label>Link Text</label>
                <div class="inpust_group">
                  <input
                    type="text"
                    class="form-control"
                    placeholder='e. g. "INSTALL APP"'
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer justify-content-center border-0 pt-2">
            <div class="footer-pages-forms">
              <div class="content">
                <div class="links-clear-data">
                  <button
                    type="button"
                    class="btn link-clear"
                    data-bs-toggle="modal"
                    data-bs-dismiss="modal"
                  >
                    <i class="ri-close-circle-line"></i>
                    <span>Cancel</span>
                  </button>
                </div>
                <a href="page-home-stories.html" class="btn btn-bid-items">
                  <p>Create Story</p>
                  <div class="ico">
                    <i class="ri-arrow-drop-right-line"></i>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "Header",
  data() {
    return {
      networkData: 1,
      productData: 1,
    };
  },
  mounted() {
    this.getDataNetwork();
    this.getDataProduct();
  },
  methods: {
    getDataNetwork() {
      axios.get(process.env.VUE_APP_API + "api/network").then((res) => {
        this.networkData = res.data.data.total;
      });
    },
    getDataProduct() {
      axios.get(process.env.VUE_APP_API + "api/product/total").then((res) => {
        this.productData = res.data.data;
      });
    },
  },
};
</script>

<style scoped>
.custom-scroll {
  overflow-x: auto;
}
.custom-scroll::-webkit-scrollbar {
  width: 0; /* Remove scrollbar space */
  background: transparent; /* Optional: just make scrollbar invisible */
  display: none;
}
/* Optional: show position indicator in red */
.custom-scroll {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.link-dark:hover {
  background: linear-gradient(45deg, #25d366, #3487f1);
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent !important;
}

.router-link-exact-active.router-link-active {
  background: linear-gradient(45deg, #25d366, #3487f1);
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent !important;
}
</style>