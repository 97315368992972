<template>
  <div>
    <Header />
    <section class="bg-white d-lg-block d-none">
      <div class="container py-4">
        <div class="row">
          <div class="col-7">
            <div class="card border-dark">
              <div class="card-header bg-dark text-white">
                <div class="d-flex justify-content-between align-items-center">
                  <h6 class="mb-0 py-2">Marketplaces Nft</h6>
                  <router-link
                    to="/nft-marketplace-list"
                    class="btn px-3 btn-xs-size bg-white rounded-pill"
                    >View More <em class="ri ri-arrow-right-line ms-2"></em
                  ></router-link>
                </div>
              </div>
              <div class="card-body p-0">
                <table
                  class="
                    table table-striped table-responsive
                    log-table-new
                    mb-0
                  "
                >
                  <thead>
                    <tr>
                      <td></td>
                      <td>Market</td>
                      <td class="text-end">Traders</td>
                      <td class="text-end">Volume</td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(market, index) in nftMarketplace"
                      :key="index"
                      class="align-middle"
                    >
                      <td class="text-center ps-2">
                        {{ index + 1 }}
                      </td>
                      <td>
                        <div
                          class="
                            d-flex
                            justify-content-start
                            align-items-center
                            gap-3
                          "
                        >
                          <img
                            :src="market.logo"
                            class="img-responsive rounded-10"
                            width="40px"
                            height="40px"
                            alt=""
                          /><router-link
                            :to="
                              '/nft-marketplace/' +
                              market.networkKey +
                              '/' +
                              market.dappKey
                            "
                            class="fw-bold text-decoration-none text-dark"
                            >{{ market.name }}</router-link
                          >
                        </div>
                      </td>
                      <td class="text-end">
                        <div>
                          <div>
                            {{ Number(market.tradersCount).toLocaleString() }}
                          </div>
                          <div
                            v-if="market.tradersCountChange > 0"
                            class="text-success"
                          >
                            {{ Number(market.tradersCountChange).toFixed(2) }}%
                          </div>
                          <div v-else class="text-danger">
                            {{ Number(market.tradersCountChange).toFixed(2) }}%
                          </div>
                        </div>
                      </td>
                      <td class="text-end">
                        <div>
                          <div>${{ market.volumeInFiat | formatNumber }}</div>
                          <div
                            v-if="market.volumeInFiatChange > 0"
                            class="text-success"
                          >
                            {{ Number(market.volumeInFiatChange).toFixed(2) }}%
                          </div>
                          <div v-else class="text-danger">
                            {{ Number(market.volumeInFiatChange).toFixed(2) }}%
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div class="card border-dark mt-4">
              <div class="card-header bg-dark text-white">
                <div class="d-flex justify-content-between align-items-center">
                  <h6 class="mb-0 py-2">Top Nft Collections</h6>
                  <router-link
                    to="/nft-collection-list"
                    class="btn px-3 btn-xs-size bg-white rounded-pill"
                    >View More <em class="ri ri-arrow-right-line ms-2"></em
                  ></router-link>
                </div>
              </div>
              <div class="card-body p-0">
                <table
                  class="
                    table table-striped table-responsive
                    log-table-new
                    mb-0
                  "
                >
                  <thead>
                    <tr>
                      <td></td>
                      <td>Name</td>
                      <td class="text-end">Volume</td>
                      <td class="text-wrap text-end">Traders</td>
                      <td class="text-end"># of Sales</td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(nft, index) in nftLists"
                      :key="index"
                      class="align-middle"
                    >
                      <td class="text-center">{{ index + 1 }}</td>
                      <td>
                        <div
                          class="
                            d-flex
                            justify-content-start
                            align-items-center
                            gap-3
                          "
                        >
                          <img
                            :src="nft.logo"
                            class="img-responsive rounded-10"
                            width="40px"
                            height="40px"
                            alt=""
                          />
                          <div class="d-flex flex-column justify-content-start">
                            <span v-if="nft.isPartial == false" class="fw-bold">
                              <router-link
                                class="text-decoration-none text-dark"
                                :to="
                                  '/nft-collection/' +
                                  nft.categoryKey +
                                  '/' +
                                  nft.platformKey +
                                  '/' +
                                  nft.linkKey +
                                  '/' +
                                  nft.logoKey
                                "
                                v-if="nft.platformKey != null"
                              >
                                {{ nft.name }}
                              </router-link>
                              <router-link
                                class="text-decoration-none text-dark"
                                :to="
                                  '/nft-collection/' +
                                  nft.categoryKey +
                                  '/' +
                                  nft.activeProtocols[0] +
                                  '/' +
                                  nft.linkKey +
                                  '/' +
                                  nft.logoKey
                                "
                                v-else
                              >
                                {{ nft.name }}
                              </router-link>
                            </span>
                            <span
                              v-else
                              class="fw-bold text-decoration-none text-dark"
                              >{{ nft.name }}</span
                            >
                            <div class="text-capitalize">
                              {{ nft.protocols[0] }}
                            </div>
                          </div>
                        </div>
                      </td>
                      <td class="text-end">
                        <div class="d-flex flex-column">
                          <div>${{ nft.volumeInFiat | formatNumber }}</div>
                          <div v-if="nft.volumeChange > 0" class="text-success">
                            {{ nft.volumeChange }}%
                          </div>
                          <div v-else class="text-danger">
                            {{ nft.volumeChange }}%
                          </div>
                        </div>
                      </td>
                      <td class="text-end">
                        <div class="d-flex flex-column">
                          <div>{{ nft.tradersCount }}</div>
                          <div
                            v-if="nft.tradersCountChange > 0"
                            class="text-success"
                          >
                            {{ nft.tradersCountChange }}%
                          </div>
                          <div v-else class="text-danger">
                            {{ nft.tradersCountChange }}%
                          </div>
                        </div>
                      </td>
                      <td class="text-end">
                        <div class="d-flex flex-column">
                          <div>{{ nft.salesCount }}</div>
                          <div
                            v-if="nft.salesCountChange > 0"
                            class="text-success"
                          >
                            {{ nft.salesCountChange }}%
                          </div>
                          <div v-else class="text-danger">
                            {{ nft.salesCountChange }}%
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div class="col-5">
            <div class="card border-dark">
              <div class="card-header bg-dark text-white">
                <div class="d-flex justify-content-between align-items-center">
                  <h6 class="mb-0 py-2">Top Sales Nft</h6>
                  <router-link
                    to="/nft-sales-list"
                    class="btn px-3 btn-xs-size bg-white rounded-pill"
                    >View More <em class="ri ri-arrow-right-line ms-2"></em
                  ></router-link>
                </div>
              </div>
              <div class="card-body p-0">
                <table
                  class="
                    table table-striped table-responsive
                    log-table-new
                    mb-0
                  "
                >
                  <thead>
                    <tr>
                      <td></td>
                      <td>NFT</td>
                      <td class="text-end">Price</td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(nftSale, index) in nftSaleLists"
                      :key="index"
                      class="align-middle"
                    >
                      <td>
                        <div class="align-middle text-center ps-1">
                          {{ index + 1 }}
                        </div>
                      </td>
                      <td class="text-end">
                        <div
                          class="
                            d-flex
                            justify-content-start
                            align-items-center
                            gap-3
                          "
                        >
                          <img
                            :src="nftSale.logo"
                            class="img-responsive rounded-10"
                            width="40px"
                            height="40px"
                            alt=""
                          />
                          <div>
                            <router-link
                              v-if="nftSale.protocol === 'ethereum'"
                              :to="
                                '/nft-details/' +
                                nftSale.collection +
                                '/' +
                                nftSale.id
                              "
                              class="
                                fw-bold
                                text-decoration-none text-dark text-break
                              "
                              >{{ nftSale.name }}</router-link
                            >
                            <span
                              v-else
                              class="
                                fw-bold
                                text-decoration-none
                                text-dark
                                text-break
                                text-start
                              "
                            >
                              {{ nftSale.name }}
                            </span>
                            <div
                              class="
                                d-flex
                                justify-content-start
                                align-items-center
                                gap-1
                              "
                            >
                              <img
                                :src="nftSale.collectionLogo"
                                width="15px"
                                height="15px"
                                alt=""
                              />
                              {{ nftSale.collectionName }}
                            </div>
                          </div>
                        </div>
                      </td>
                      <td class="text-end">
                        <div class="d-flex flex-column">
                          <div>${{ nftSale.priceInFiat | formatNumber }}</div>
                          <small class="text-secondary">
                            {{ nftSale.priceInCrypto }}
                            {{ nftSale.tokenSymbol }}
                          </small>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div class="card border-dark mt-4">
              <div class="card-header bg-dark text-white">
                <div class="d-flex justify-content-between align-items-center">
                  <h6 class="mb-0 py-2">Top Fractional Nft</h6>
                  <router-link
                    to="/nft-fractional-list"
                    class="btn px-3 btn-xs-size bg-white rounded-pill"
                    >View More <em class="ri ri-arrow-right-line ms-2"></em
                  ></router-link>
                </div>
              </div>
              <div class="card-body p-0">
                <table
                  class="
                    table table-striped table-responsive
                    log-table-new
                    mb-0
                  "
                >
                  <thead>
                    <tr>
                      <td></td>
                      <td>Name</td>
                      <td class="text-end">Market Cap</td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(fraction, index) in nftFraction.slice(0, 25)"
                      :key="index"
                      class="align-middle"
                    >
                      <td class="text-center">{{ index + 1 }}</td>
                      <td>
                        <div
                          class="
                            d-flex
                            justify-content-start
                            align-items-center
                            gap-3
                          "
                        >
                          <img
                            :src="fraction?.images?.image_url"
                            class="img-responsive rounded-10"
                            width="40px"
                            height="40px"
                            alt=""
                          />
                          <div class="d-flex flex-column justify-content-start">
                            <span
                              class="fw-bold text-decoration-none text-dark"
                            >
                              {{ fraction.name }}
                            </span>
                            <a
                              :href="fraction.links.niftex"
                              class="text-dark text-decoration-none"
                              >{{ fraction.platform.name }}</a
                            >
                          </div>
                        </div>
                      </td>
                      <td class="text-end">
                        <span class="text-end">
                          ${{ fraction.marketCap | formatNumber }}
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="bg-white d-lg-none d-block">
      <div class="container py-4">
        <div class="row">
          <div class="col-12">
            <div class="card border-dark">
              <div class="card-header bg-dark text-white">
                <div class="d-flex justify-content-between align-items-center">
                  <h6 class="mb-0 py-2">Marketplaces Nft</h6>
                  <router-link
                    to="/nft-marketplace-list"
                    class="btn px-3 btn-xs-size bg-white rounded-pill"
                    >View More <em class="ri ri-arrow-right-line ms-2"></em
                  ></router-link>
                </div>
              </div>
              <div class="card-body p-0">
                <div class="table-responsive">
                  <table class="table table-striped log-table-new mb-0">
                    <thead>
                      <tr>
                        <td></td>
                        <td>Market</td>
                        <td class="text-end">Traders</td>
                        <td class="text-end">Volume</td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(market, index) in nftMarketplace"
                        :key="index"
                        class="align-middle"
                      >
                        <td class="text-center ps-2">
                          {{ index + 1 }}
                        </td>
                        <td>
                          <div
                            class="
                              d-flex
                              justify-content-start
                              align-items-center
                              gap-3
                            "
                          >
                            <img
                              :src="market.logo"
                              class="img-responsive rounded-10"
                              width="40px"
                              height="40px"
                              alt=""
                            /><router-link
                              :to="
                                '/nft-marketplace/' +
                                market.networkKey +
                                '/' +
                                market.dappKey
                              "
                              class="fw-bold text-decoration-none text-dark"
                              >{{ market.name }}</router-link
                            >
                          </div>
                        </td>
                        <td class="text-end">
                          <div>
                            <div>
                              {{ Number(market.tradersCount).toLocaleString() }}
                            </div>
                            <div
                              v-if="market.tradersCountChange > 0"
                              class="text-success"
                            >
                              {{
                                Number(market.tradersCountChange).toFixed(2)
                              }}%
                            </div>
                            <div v-else class="text-danger">
                              {{
                                Number(market.tradersCountChange).toFixed(2)
                              }}%
                            </div>
                          </div>
                        </td>
                        <td class="text-end">
                          <div>
                            <div>${{ market.volumeInFiat | formatNumber }}</div>
                            <div
                              v-if="market.volumeInFiatChange > 0"
                              class="text-success"
                            >
                              {{
                                Number(market.volumeInFiatChange).toFixed(2)
                              }}%
                            </div>
                            <div v-else class="text-danger">
                              {{
                                Number(market.volumeInFiatChange).toFixed(2)
                              }}%
                            </div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            <div class="card border-dark mt-4">
              <div class="card-header bg-dark text-white">
                <div class="d-flex justify-content-between align-items-center">
                  <h6 class="mb-0 py-2">Top Nft Collections</h6>
                  <router-link
                    to="/nft-collection-list"
                    class="btn px-3 btn-xs-size bg-white rounded-pill"
                    >View More <em class="ri ri-arrow-right-line ms-2"></em
                  ></router-link>
                </div>
              </div>
              <div class="card-body p-0">
                <div class="table-responsive">
                  <table
                    class="
                      table table-striped table-responsive
                      log-table-new
                      mb-0
                    "
                  >
                    <thead>
                      <tr class="align-middle">
                        <td></td>
                        <td>Name</td>
                        <td class="text-end">Volume</td>
                        <td class="text-wrap text-end">Traders</td>
                        <td class="text-end text-nowrap"># of Sales</td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(nft, index) in nftLists"
                        :key="index"
                        class="align-middle"
                      >
                        <td class="text-center">{{ index + 1 }}</td>
                        <td>
                          <div
                            class="
                              d-flex
                              justify-content-start
                              align-items-center
                              gap-3
                            "
                          >
                            <img
                              :src="nft.logo"
                              class="img-responsive rounded-10"
                              width="40px"
                              height="40px"
                              alt=""
                            />
                            <div
                              class="d-flex flex-column justify-content-start"
                            >
                              <span
                                v-if="nft.isPartial == false"
                                class="fw-bold"
                              >
                                <router-link
                                  class="text-decoration-none text-dark"
                                  :to="
                                    '/nft-collection/' +
                                    nft.categoryKey +
                                    '/' +
                                    nft.platformKey +
                                    '/' +
                                    nft.linkKey +
                                    '/' +
                                    nft.logoKey
                                  "
                                  v-if="nft.platformKey != null"
                                >
                                  {{ nft.name }}
                                </router-link>
                                <router-link
                                  class="text-decoration-none text-dark"
                                  :to="
                                    '/nft-collection/' +
                                    nft.categoryKey +
                                    '/' +
                                    nft.activeProtocols[0] +
                                    '/' +
                                    nft.linkKey +
                                    '/' +
                                    nft.logoKey
                                  "
                                  v-else
                                >
                                  {{ nft.name }}
                                </router-link>
                              </span>
                              <span
                                v-else
                                class="fw-bold text-decoration-none text-dark"
                                >{{ nft.name }}</span
                              >
                              <div class="text-capitalize">
                                {{ nft.protocols[0] }}
                              </div>
                            </div>
                          </div>
                        </td>
                        <td class="text-end">
                          <div class="d-flex flex-column">
                            <div>${{ nft.volumeInFiat | formatNumber }}</div>
                            <div
                              v-if="nft.volumeChange > 0"
                              class="text-success"
                            >
                              {{ nft.volumeChange }}%
                            </div>
                            <div v-else class="text-danger">
                              {{ nft.volumeChange }}%
                            </div>
                          </div>
                        </td>
                        <td class="text-end">
                          <div class="d-flex flex-column">
                            <div>{{ nft.tradersCount }}</div>
                            <div
                              v-if="nft.tradersCountChange > 0"
                              class="text-success"
                            >
                              {{ nft.tradersCountChange }}%
                            </div>
                            <div v-else class="text-danger">
                              {{ nft.tradersCountChange }}%
                            </div>
                          </div>
                        </td>
                        <td class="text-end">
                          <div class="d-flex flex-column">
                            <div>{{ nft.salesCount }}</div>
                            <div
                              v-if="nft.salesCountChange > 0"
                              class="text-success"
                            >
                              {{ nft.salesCountChange }}%
                            </div>
                            <div v-else class="text-danger">
                              {{ nft.salesCountChange }}%
                            </div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 mt-4">
            <div class="card border-dark">
              <div class="card-header bg-dark text-white">
                <div class="d-flex justify-content-between align-items-center">
                  <h6 class="mb-0 py-2">Top Sales Nft</h6>
                  <router-link
                    to="/nft-sales-list"
                    class="btn px-3 btn-xs-size bg-white rounded-pill"
                    >View More <em class="ri ri-arrow-right-line ms-2"></em
                  ></router-link>
                </div>
              </div>
              <div class="card-body p-0">
                <div class="table-responsive">
                  <table
                    class="
                      table table-striped table-responsive
                      log-table-new
                      mb-0
                    "
                  >
                    <thead>
                      <tr>
                        <td></td>
                        <td>NFT</td>
                        <td class="text-end">Price</td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(nftSale, index) in nftSaleLists"
                        :key="index"
                        class="align-middle"
                      >
                        <td>
                          <div class="align-middle text-center ps-1">
                            {{ index + 1 }}
                          </div>
                        </td>
                        <td class="text-end">
                          <div
                            class="
                              d-flex
                              justify-content-start
                              align-items-center
                              gap-3
                            "
                          >
                            <img
                              :src="nftSale.logo"
                              class="img-responsive rounded-10"
                              width="40px"
                              height="40px"
                              alt=""
                            />
                            <div class="text-start">
                              <router-link
                                v-if="nftSale.protocol === 'ethereum'"
                                :to="
                                  '/nft-details/' +
                                  nftSale.collection +
                                  '/' +
                                  nftSale.id
                                "
                                class="
                                  fw-bold
                                  text-decoration-none
                                  text-dark
                                  text-break
                                  text-start
                                "
                                >{{ nftSale.name }}</router-link
                              >
                              <span
                                v-else
                                class="
                                  fw-bold
                                  text-decoration-none
                                  text-dark
                                  text-break
                                  text-start
                                "
                              >
                                {{ nftSale.name }}
                              </span>
                              <div
                                class="
                                  d-flex
                                  justify-content-start
                                  align-items-start
                                  gap-1
                                "
                              >
                                <img
                                  :src="nftSale.collectionLogo"
                                  width="15px"
                                  height="15px"
                                  alt=""
                                />
                                {{ nftSale.collectionName }}
                              </div>
                            </div>
                          </div>
                        </td>
                        <td class="text-end">
                          <div class="d-flex flex-column">
                            <div>${{ nftSale.priceInFiat | formatNumber }}</div>
                            <small class="text-secondary">
                              {{ nftSale.priceInCrypto }}
                              {{ nftSale.tokenSymbol }}
                            </small>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            <div class="card border-dark mt-4">
              <div class="card-header bg-dark text-white">
                <div class="d-flex justify-content-between align-items-center">
                  <h6 class="mb-0 py-2">Top Fractional Nft</h6>
                  <router-link
                    to="/nft-fractional-list"
                    class="btn px-3 btn-xs-size bg-white rounded-pill"
                    >View More <em class="ri ri-arrow-right-line ms-2"></em
                  ></router-link>
                </div>
              </div>
              <div class="card-body p-0">
                <div class="table-responsive">
                  <table
                    class="
                      table table-striped table-responsive
                      log-table-new
                      mb-0
                    "
                  >
                    <thead>
                      <tr>
                        <td></td>
                        <td>Name</td>
                        <td class="text-end">Market Cap</td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(fraction, index) in nftFraction.slice(0, 25)"
                        :key="index"
                        class="align-middle"
                      >
                        <td class="text-center">{{ index + 1 }}</td>
                        <td>
                          <div
                            class="
                              d-flex
                              justify-content-start
                              align-items-center
                              gap-3
                            "
                          >
                            <img
                              :src="fraction?.images?.image_url"
                              class="img-responsive rounded-10"
                              width="40px"
                              height="40px"
                              alt=""
                            />
                            <div
                              class="d-flex flex-column justify-content-start"
                            >
                              <span
                                class="fw-bold text-decoration-none text-dark"
                              >
                                {{ fraction.name }}
                              </span>
                              <a
                                :href="fraction.links.niftex"
                                class="text-dark text-decoration-none"
                                >{{ fraction.platform.name }}</a
                              >
                            </div>
                          </div>
                        </td>
                        <td class="text-end">
                          <span class="text-end">
                            ${{ fraction.marketCap | formatNumber }}
                          </span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <Footer />
  </div>
</template>

<script>
import Header from "../components/Header.vue";
import Footer from "../components/Footer.vue";
import axios from "axios";

export default {
  name: "Nft",
  components: { Footer, Header },
  data() {
    return {
      nftLists: [],
      nftSaleLists: [],
      nftMarketplace: [],
      nftFraction: [],
    };
  },
  mounted() {
    this.getNftList();
    this.getNftSaleList();
    this.getNftMarketplace();
    this.getNftFraction();
  },
  methods: {
    getNftList() {
      axios
        .get(
          "https://api-social-data.dinodapps.com/nft/v2.1/collection/day?limit=25&page=1&currency=USD&sort=volumeInFiat&order=desc"
        )
        .then((res) => {
          this.nftLists = res.data.results;
        });
    },
    getNftSaleList() {
      axios
        .get(
          "https://api-social-data.dinodapps.com/nft/v2.1/sale/day?limit=5&page=1&currency=USD&sort=priceInFiat&order=desc&excludeDappId=12792"
        )
        .then((res) => {
          this.nftSaleLists = res.data.results;
        });
    },
    getNftMarketplace() {
      axios
        .get(
          "https://api-social-data.dinodapps.com/nft/v2.1/marketplace/day?limit=5&page=1&currency=USD&sort=volumeInFiat&order=desc"
        )
        .then((res) => {
          this.nftMarketplace = res.data.results;
        });
    },
    getNftFraction() {
      axios.get("https://shardmarketcap.io/api/data").then((res) => {
        this.nftFraction = res.data;
      });
    },
  },
};
</script>